import type { JSX } from 'solid-js';

type Props = JSX.SvgSVGAttributes<SVGSVGElement>;

export function SquareLogo(props: Props) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 33 33">
			<path d="M0 28.4v-.1l.1-.5V26l.1-1.2v-6.2l-.1-1V8a20.5 20.5 0 0 0 0-2.3v-3l.2-.4c.3-.6.7-.9 1-1.1l.4-.3.4-.2h.2l.2-.1h.2L3 .5h.2l1 .2h.4l.1.1h4L10 1h.8l.9.1.7.1H24.7L25 1h1.5l.3-.1h1.5L30 .7H30.6l1.7-.2c.2 0 .3.3.4.8l.1.5v5.5l.1 1.3V11l-.1.4v.9l-.1 1-.1.8v3.3l-.1 1.1V23.3a19.1 19.1 0 0 0 .4 4.3v.7l-.1 1.1-.3.9-.2.5-.2.2-.2.3v.2h-.2l-.2.3h-.1l-.6.4H30.2l-.4.1h-.5l-.7-.3-.8-.2H27l-.2-.1h-5.1l-.2.1H10.5l-.4-.1H7.7L7 32l-.6.1H4.9l-1 .2h-.4l-.8.2-.7.1-.4-.2-.5-.2-.3-.3-.5-.7V31l-.2-.6v-2Zm3.6.7h2.9l.8.1v.1h.2l.6.2h.3l.4.1H10l.3.1h1.1l.5-.1h7.9l.5.1H20.8v-.1h.2l.3.1c0-.1.3-.2.4 0v-.1h1.4l.4-.1h.2l.6-.1h.2l.6-.1.5-.2h4.1v-1.9l-.1-1.5a8.2 8.2 0 0 1 0-1.3l.1-.7.1-1.4.2-1.4V19.7l.2-3.6V7.7L30 6.1V3.4H26.8l-.4.1H24.2l-.4.1H12.6l-1.2.2H4.3L4 4H3l-.3.1.1 2.4v1.1l-.1.3V9l-.1.9a22.6 22.6 0 0 0 0 3v1.1l.2 1 .1.7V20.4l-.1.4v1a32.7 32.7 0 0 0-.2 3.7v1.1c.2.6.2 1.2.2 2v.5h.8Zm-2.6.5Z" />
			<path d="M11 10.3h.2l1.4.1h1.2l.7.2 1-.1a363.8 363.8 0 0 0 .6-.1c.2-.1.5-.3.9-.1l.3-.1h1.3a3 3 0 0 1 2-.3h-.1c1-.4 1.8.1 2.3.6.3.3.5.7.3 1.1-.5.7-1 .5-1.4.4l-.8-.1h-2.6l-.6.2.2.5v.4l-.2.8v.6l-.1.6V16.2l-.2 1v1l.1.7.1 1.4v1.4l.1.7c0 .3 0 .6-.3.8-.8.3-1.3-.1-1.7-.4-.4-.4-.5-.7-.5-1.1v-1.3l.3-.3c-.1-.2-.3-.3-.3-.5V19l.1-.5V18l.1-1.4v-.5l-.2-1.9.2-.2.1.3-.3-2h-3.9l-.5-.3c-.5-.3-1-.6-1-1.4.3-.7.9-.5 1.2-.3Z" />
		</svg>
	);
}
